<template>
  <v-card elevation="0">
    <v-progress-linear v-if="loading" indeterminate color="accent" />

    {{ error }}
    <ul>
      <li v-for="shipmentId in shipments" :key="shipmentId">
        <span class="label">{{ shipmentLabel(shipmentId) }}:</span>
        <ul>
          <li>{{ parcelList(shipmentId) }}</li>
        </ul>
      </li>
    </ul>
  </v-card>
</template>

<script>
import ParcelService from '@/services/ParcelService.js';
export default {
  props: {
    parcelSet: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      loading: false,
      parcels: [],
      error: null
    };
  },
  computed: {
    // parcelList() {
    //   return this.parcels.map((x) => x.id).join(", ");
    // },
    shipments() {
      return [...Object.keys(this.parcels)];
    }
  },
  watch: {
    parcelSet() {
      this.load();
    }
  },
  mounted() {
    console.log('parcelSet.mounted()');
    this.load();
  },

  methods: {
    async load() {
      this.loading = true;
      this.error = null;
      this.parcels = {};
      try {
        console.log('loading parcels for ParcelSet.id=', this.parcelSet.id);

        let rsp = await ParcelService.getItems(
          {
            filters: [
              {
                field: 'parcel_set_id',
                operation: '=',
                value: this.parcelSet.id
              }
            ]
          },
          ''
        );
        console.log('items loaded:', rsp);
        let parcels = rsp[0];
        if (parcels.length != this.parcelSet.boxes) {
          this.error = `Only ${this.parcels.length} parcels found, but ${this.parcelSet.boxes} expected`;
        }

        this.parcels = parcels.reduce((acc, p) => {
          let label = 'unassigned';

          if (p.shipment) {
            label = p.shipment;
          }

          if (!acc[label]) {
            acc[label] = [];
          }
          acc[label].push(p);
          return acc;
        }, {});
        console.log('parcels:', this.parcels);
      } catch (error) {
        console.log('error:', error);
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    parcelList(shipmentId) {
      console.log('parcelList(', shipmentId, ')');
      return this.parcels[shipmentId].map(x => x.id).join(', ');
    },
    shipmentLabel(id) {
      if (id === 'unassigned') {
        return this.$t('warehouse.parcelset.unassigned');
      } else {
        return this.$t('warehouse.parcelset.shipment', {
          shipment: id
        });
      }
    }
  }
};
</script>

<style lang="sass">
li .label
  font-weight: 500
  padding
</style>
